<template>
    <grid-view 
        title="Инструкции интерактивов"
        url-update="/interactives-instruction/update/:id"
        store-module="interactives_instruction"
        :headers="[
            { text: 'Интерактив', value: 'name', width: 1, sortable: false },
            { text: '', value: 'edit', width: 1, sortable: false }
        ]"
        :format="formatItems"
    />
</template>
<script>
import GridView from '@/components/crud/GridView.vue'
export default {
    name: 'InteractivesInstructionIndex',
    components: { GridView },
    methods: {
        formatItems (items) { return items.map(item => ({
            ...item,
            name: this.$const.editorBlockTypes[item.name] || item.name
        })) } 
    }
}
</script>